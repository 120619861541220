import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";

function MapboxFleet(props) {
  const navigate = useNavigate();

  const h4Element = document.getElementById("popup_h4");

  if (h4Element) {
    h4Element?.addEventListener("click", () => {
      const itemAttributeValue = h4Element.getAttribute("item");
      const item = JSON.parse(itemAttributeValue);
      // Now you can access the item object and use it as needed
      navigate(`/fleetPage/${item.fleetName}`, {
        state: { data: item },
      });
    });
  }

  mapboxgl.accessToken =
    "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";
  const { fleetList, markers } = useSelector((state) => state.fleetList);

  const [markerArray, setmarkerArray] = useState([]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  
  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: "map-container-id",
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        center: [fleetList[0]?.longitude, fleetList[0]?.latitude],
        zoom: 8,
      });

      fleetList?.forEach((item) => {
        const el = document.createElement("div");
        el.className = "fleet_marker";
        new mapboxgl.Marker(el)
          .setLngLat([item.longitude, item.latitude])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              '<div class="Dasboard_page_individual_fleet_box_wrapper">' +
                `<h4 class="Dashboard_page_individual_fleet_Heading_Green_h4" id="popup_h4" item='${JSON.stringify(
                  item
                )}'>` +
                item.fleetName +
                "</h4>" +
                '<div class="Dasboard_page_individual_fleet_box_inner_div">' +
                "<p> Location </p>" +
                "<p>" +
                item.location +
                "</p>" +
                "</div>" +
                '<div class="Dasboard_page_individual_fleet_box_inner_div">' +
                "<p> Robots </p>" +
                "<p>" +
                item.robots.length +
                "</p>" +
                "</div>" +
                '<div class="Dasboard_page_individual_fleet_box_inner_div">' +
                "<p> Coordinates </p>" +
                "<p>" +
                parseFloat(item.map.latitude).toFixed(2) +
                ", " +
                parseFloat(item.map.longitude).toFixed(2) +
                "</p>" +
                "</div>" +
                "</div>"
            )
          )
          .addTo(newMap);
      });

      newMap.on("move", () => {
        setZoom(newMap.getZoom().toFixed(2));
      });
      setMap(newMap);
      // mapRef.current = newMap;
    };
    if (!map) {
      initializeMap();
    }
  }, [map, setMap, props.mapchecktwo]);

  return (
    <div style={{ height: "80vh" }}>
      <div
        id="map-container-id"
        // ref={mapContainer}
        className="map-container"
        style={{ height: "100%" }}
      ></div>
    </div>
  );
}

export default MapboxFleet;
