import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletefleet } from "../API";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import DashboardBottomBar from "../components/DashboardBottomBar";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";

function FleetPage(props) {
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);
  const emailId = localStorage.getItem("useremail");
  // const [fleetlistData, setfleetlistData] = useState();
  const token = localStorage.getItem("token");
  const toastmsg = (msg) => toast(msg);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fleetList } = useSelector((state) => state.fleetList);
  const isopen = useSelector((state) => state.dashboardopenReducer);

  if (!emailId) {
    navigate("/");
  }

  useEffect(() => {
    if (fleetList.length === 0) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [googlemap, setgooglemap] = useState(false);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const handlemaptoggle = () => {
    setgooglemap(!googlemap);
  };

  const handleSingleFleet = (fleetData) => {
    localStorage.setItem("fleetData", JSON.stringify(fleetData));
    navigate(`/fleetPage/${fleetData.fleetName}`, {
      state: { fleetData: fleetData },
    });
  };

  const handleAddFleet = () => {
    navigate("/addfleet");
  };

  const handleFleetEdit = (item) => {
    navigate(`/editfleet/${item._id}`, { state: { data: item } });
  };

  const handledeleteFleet = (item) => {
    // setfleetlistData(fleetData.filter((itm) => itm._id !== item._id));
    deletefleet(item._id, token)
      .then((res) => {
        toastmsg(res.data.message);
        // console.log('Delete Fleet Response',res)
      })
      .catch((err) => {
        toastmsg(err.data.message);
        //  console.log('Delete Fleet Error',err)
      });
  };
  const { sidebarcollapse } = useSelector((state) => state.login);
  return (
    <>
      <Header />
      <div className="Dashboard_page_Whole_content_wrapper_main"></div>
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="fleetpage"
            />
            <DashboardBottomBar active="fleetpage" />
          </div>

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              style={{ height: "100%" }}
              className={isopen.userlogindata ? "" : ""}
            >
              <div className="Dashboard_page_robot_card_heading_wrapper">
                <h4 className="Dashboard_page_Robot_Card_heading">Fleets</h4>
              </div>
              <div>
                <table
                  style={{ width: "100%" }}
                  className="Fleet_page_table_Main_whole"
                >
                  <thead>
                    <tr>
                      <th> Fleet </th>
                      <th> Area </th>
                      <th> Latitude</th>
                      <th> Longitude</th>
                      <th> Date Created</th>
                      <th> Robots</th>
                      <th> </th>
                      {/* <th> Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {fleetList &&
                      fleetList?.map((item, index) => {
                        return (
                          <tr
                            key={index}
                            // onClick={() => handleSingleFleet(item)}
                          >
                            <td> {item.fleetName}</td>
                              <td> {item.map.location}</td>
                              <td> {item.map.latitude}</td>
                              <td> {item.map.longitude}</td>
                              <td>
                                {" "}
                                {item.map.timezone ? new Date(
                                  new Date(item.createdAt).toLocaleString(
                                    "en-US",
                                    {
                                      timeZone: item.map.timezone.split("-")[0],
                                    }
                                  )
                                ).toDateString() + " "+ item.map.timezone.split("-")[1]: (
                                  item.createdAt + "UTC"
                                )}{" "}
                                
                              </td>
                              <td> {item.robots.length} </td>
                            <td className="FleetPage_table_action_td">
                              <img
                                className="cursor_pointer"
                                src="/assets/images/arrow-link-icon.svg"
                                onClick={() => handleSingleFleet(item)}
                                alt="fleet_breadcrumb_arrow"
                              />
                              {/* <img
                              className="cursor_pointer"
                              src="/assets/images/b.svg"
                              onClick={() => handleFleetEdit(item)}
                              alt="fleet_edit"
                            /> */}
                                {/* <Button onClick={handleOpen}>
                              <img
                                className="cursor_pointer"
                                src="/assets/images/Deleteiconlist.svg"
                                alt="fleet_delete"
                              />
                            </Button> */}
                                <Modal
                                  open={open}
                                  aria-labelledby="modal-modal-title"
                                  aria-describedby="modal-modal-description"
                                >
                                  <Box sx={style}>
                                    <div className="Delete_modal_close_btn">
                                      <p className="Modal-wrapper-p">
                                        {" "}
                                        Are you sure to want to delete the fleet
                                        ?
                                      </p>
                                      <CloseIcon
                                        onClick={handleClose}
                                        style={{
                                          cursor: "pointer",
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      />
                                    </div>
                                    <div className="Modal_content_button_wrapper">
                                      <button
                                        onClick={handleClose}
                                        className="Modal-btn"
                                      >
                                        {" "}
                                        No{" "}
                                      </button>
                                      <button
                                        onClick={() => {
                                          handledeleteFleet(item);
                                          handleClose();
                                        }}
                                        className="Modal-btn Modal-btn-red"
                                      >
                                        {" "}
                                        Yes
                                      </button>
                                    </div>
                                  </Box>
                                </Modal>
                              </td>
                              {/* <td className="FleetPage_table_status_td">
                            <div className="Individual_fleetPage_map_toggle">
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={googlemap}
                                    onChange={handlemaptoggle}
                                    name="googlemap"
                                  />
                                }
                                label=""
                              />
                            </div>
                          </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
             
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button className="FleetPage_plusIcon" onClick={handleAddFleet}>
        <AddIcon />
      </button> */}
    </>
  );
}

export default FleetPage;
