import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Switch } from "@mui/material";
import { Dialog, Menu, Popover } from "@headlessui/react";
import { SlArrowDown } from "react-icons/sl";
import { Transition } from "@headlessui/react";
import OrderList from "../subcomponents/OrderList";
import OrderListNew from "../subcomponents/OrderListNew";
import FleetPageMapPage from "./FleetPageMapPage";
import FleetPagerobotPage from "./FleetPagerobotPage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import { dispatch } from "d3";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import VideoStream from "./VideoStream";
import Dropdown from "react-bootstrap/Dropdown";
import OrdersReports from "../subcomponents/OrdersReports";

function FleetPageTopBar(props) {
  const { fleetList } = useSelector((state) => state.fleetList);

  const [topbar, settopbar] = useState("orders");
  const [googlemap, setgooglemap] = useState(true);
  const [selectedFleet, setSelectedFleet] = useState();

  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  // const fleetData = useLocation();
  const emailId = localStorage.getItem("useremail");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handletoplink = (value) => {
    settopbar(value);
  };

  const handlemaptoggle = () => {
    setgooglemap(!googlemap);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 36,
    height: 16,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#000",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark"
              ? "rgb(189,196,224)"
              : "rgb(189,196,224)",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 12,
      height: 12,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === "light" ? "#000" : "rgb(189,196,224)",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      color: "rgb(189,196,224)",
      border: "2px solid rgb(189,196,224)",
    },
  }));

  useEffect(() => {
    if (!fleetList) {
      setSelectedFleet(fleetData?.fleetName);
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [fleetList, dispatch, emailId, fleetData?.fleetName]);

  return (
    <>
      <div className="Dashboard_page_rightSide_content_heading">
        <div className="flex items-center justify-center h-full cursor-pointer">
          <p className="FleetView_page_haeding_tag">
            {" "}
            <span
              onClick={() => {
                navigate("/fleetPage");
              }}
            >
              Fleet
            </span>{" "}
            <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            {/* {fleetList.length > 1 ? (
              <Menu
                as="div"
                className="relative inline-block h-full text-left w-max"
              >
                <Menu.Button className="flex w-full justify-between h-auto focus:outline-none text-[#00B7D4]">
                  <span className="text-[#00B7D4]">
                    {selectedFleet ? selectedFleet : fleetList[0]?.fleetName}
                  </span>
                  <SlArrowDown className="w-4 h-4 ml-2 font-semibold text-white" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-max mt-2 origin-top-right !bg-[#1c1e2b] !text-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="w-full">
                      {fleetList?.map((fleet, index) => (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                navigate(`/fleetPage/${fleet?.fleetName}`);
                                setSelectedFleet(fleet?.fleetName);
                              }}
                              className={`${
                                active
                                  ? "!bg-[#00B7D4] !text-white"
                                  : "!bg-white !text-[#00B7D4]"
                              } group flex w-full px-2 items-center justify-end rounded-md py-2 text-sm`}
                            >
                              <span className="!text-lg">
                                {fleet?.fleetName}
                              </span>
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : ( */}
              <span className="FleetView_page_Heading_fleetName">
                {props.fleetData
                  ? props.fleetData.fleetName
                  : fleetData.fleetName}
              </span>
            {/* )} */}
            {/* </div> */}
          </p>
        </div>
        <div>
          <div className="FleetPage_top_bar_left_side">
            <div
              className={topbar === "monitor" ? "top_bar_active" : ""}
              onClick={() => handletoplink("monitor")}
            >
              {" "}
              Monitor
            </div>
            {/* <div
              className={topbar === "maps" ? "top_bar_active" : ""}
              onClick={() => handletoplink("maps")}
            >
              {" "}
              Maps{" "}
            </div> */}
            <div
              className={topbar === "orders" ? "top_bar_active" : ""}
              onClick={() => handletoplink("orders")}
            >
              {" "}
              Order Dashboard
            </div>
            {/* {process.env.REACT_APP_SHOW_VIDEOS? */}

            <div
              className={topbar === "videostream" ? "top_bar_active" : ""}
              onClick={() => handletoplink("videostream")}
            >
              {" "}
              Collective Videos{" "}
            </div>

            {/* <div
              className={topbar === "reports" ? "top_bar_active" : ""}
              onClick={() => handletoplink("reports")}
            >
              {" "}
              Reports{" "}
            </div> */}
            {/* :null} */}
          </div>
          <Dropdown
            onSelect={(eventKey) => {
              handletoplink(eventKey);
            }}
            id="menu_dropdown_select"
          >
            <Dropdown.Toggle id="dropdown-basic">{"Robots"}</Dropdown.Toggle>
            <Dropdown.Menu className="custom-dropdown-menu">
              <Dropdown.Item key={"monitor"} eventKey={"monitor"}>
                Monitor
              </Dropdown.Item>
              <Dropdown.Item key={"orders"} eventKey={"orders"}>
                Order Dashboard
              </Dropdown.Item>
              <Dropdown.Item key={"videostream"} eventKey={"videostream"}>
                Collective Video
              </Dropdown.Item>
              {/* <Dropdown.Item key={"reports"} eventKey={"reports"}>
                Reports
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* {topbar === "robots" ? (
          <div className="FleetPage_map_toggle_wrapper d-flex-center" >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={googlemap}
                  onChange={handlemaptoggle}
                  name="googlemap"
                />
              }
              label=""
            />
            <p> Map</p>
          </div>
        ) : null} */}
      </div>
      {topbar === "monitor" ? (
        <FleetPagerobotPage
          googlemap={googlemap}
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "maps" ? (
        <FleetPageMapPage
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "videostream" ? (
        <VideoStream
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : topbar === "reports" ? (
        <OrdersReports
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      ) : (
        <OrderListNew
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />
      )}
    </>
  );
}

export default FleetPageTopBar;
