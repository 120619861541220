import React from "react";
import { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";

function MarkerInfowindow(props) {
  const [isopen, setisopen] = useState(false);
  const handleinfowindow = () => {
    setisopen(!isopen);
  };
  return (
    <>
      <Marker
        onClick={handleinfowindow}
        position={{ lat: props.latitde, lng: props.logitde }}
        icon={{
          url: "/assets/images/robot-icon-pickup_1.svg",
          scaledSize: new window.google.maps.Size(30, 30),
        }}
        labelAnchor={new window.google.maps.Point(0, 0)}
        labelStyle={{}}
      >
        {isopen ? (
          <InfoWindow>
            <div style={{ color: `black` }}>
              <p style={{ fontWeight: "600" }}>
                {" "}
                RobotID: <span> {props.itm.robotId}</span>
              </p>
              <p style={{ fontWeight: "600" }}>
                {" "}
                Latitude: <span>{props.itm.latitude}</span>
              </p>
              <p style={{ fontWeight: "600" }}>
                {" "}
                Longitude: <span>{props.itm.longitude}</span>
              </p>
            </div>
          </InfoWindow>
        ) : null}
      </Marker>
    </>
  );
}

export default MarkerInfowindow;
