import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Listbox, Menu } from "@headlessui/react";
import { SlArrowDown } from "react-icons/sl";
import { Transition } from "@headlessui/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { toast } from "react-toastify";
// import { fleetData } from "../Data/FleetData";
import StackedBarChart from "../Charts/StackedBarChart";
import Pagination from "@mui/material/Pagination";
import Loader from "./Loader";
// import TotalOrdersChart from "../Charts/TotalOrdersChart";
import Calendar from "../components/Calendar";
// import DoughnutChart from "../Charts/DoughnutChart";
import RobotPolarChart from "../Charts/RobotPolarChart";
import OrdersHeatMap from "./OrdersHeatMap";
import CabinPolarChart from "../Charts/CabinPolarChart";
import CustomAccordion from "../components/CustomAccordion";
import Header from "../components/Header";
import DashboardSideBar from "./DashboardSideBar";
import DashboardBottomBar from "../components/DashboardBottomBar";
import axios from "axios";
import * as XLSX from "xlsx";

const OrdersReports = (props) => {
  const [chartOrderData, setChartOrderData] = useState();
  const [analyticsData, setAnalyticsData] = useState({});
  const [isFleetDropdownOpen, setIsFleetDropdownOpen] = useState(false);
  const [selectedFleetDetails, setSelectedFleetDetails] = useState([]);
  const [isRobotDropdownOpen, setIsRobotDropdownOpen] = useState(false);
  const [selectedRobotDetails, setSelectedRobotDetails] = useState([]);
  const [currentSize, setCurrentSize] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showCount, setShowCount] = useState();
  const [paginationlength, setPaginationLength] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [stackedBarDataKey, setStackedBarDataKey] = useState({
    label: "Day of Week",
    value: "dayOfWeek",
  });
  const dispatch = useDispatch();
  const size = 5;
  const token = localStorage.getItem("token");
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  // const navigate = useNavigate();

  const isopen = useSelector((state) => state.dashboardopenReducer);
  const { sidebarcollapse } = useSelector((state) => state.login);

  const { fleetList } = useSelector((state) => state.fleetList);
  const { startDate, endDate } = useSelector(
    (state) => state.datefilterreducer
  );

  const chartTimePeriods = [
    { label: "Day of Week", value: "dayOfWeek" },
    { label: "Hour of Day", value: "hourOfDay" },
    { label: "Month of Year", value: "monthOfYear" },
  ];

  const paginationTheme = createTheme({
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "white",
              height: "28px",
              minWidth: "28px",
            },
          },
          ul: {
            "& .Mui-selected": {
              backgroundColor: "#1C1E2A !important", // background color for the selected option
              color: "white", // text color for the selected option
              "&:hover": {
                backgroundColor: "#0099C0", // background color for the selected option on hover
              },
            },
          },
          // li: {
          //   root: {
          //     "& .MuiPaginationItem-text": {
          //       color: "white",
          //     },
          //   },
          // },
        },
      },
    },
  });
  const emailId = localStorage.getItem("useremail");
  useEffect(() => {
    dispatch(fleetListAndRobotStatusByUserEmail(emailId));
  }, []);

  const handlePagination = (event, value) => {
    setPage(value);
    setCurrentPage(value);
  };
  function isColumnEmpty(column) {
    return column.every(value => value === 0 || value === '-');
  }
  
  function filterEmptyColumns(data) {
    const columns = Object.keys(data[0]);
    const nonEmptyColumns = columns.filter(column => !isColumnEmpty(data.map(row => row[column])));
    return data.map(row => {
      const filteredRow = {};
      nonEmptyColumns.forEach(column => {
        filteredRow[column] = row[column];
      });
      return filteredRow;
    });
  }
  useEffect(() => {
    if (loader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loader]);
  const customFormatTime = (seconds) => {
    let convertedTime = convertSeconds(seconds);

    let convertedTimeString = "";

    if (convertedTime.years && convertedTime.years !== 0) {
      convertedTimeString += `${convertedTime.years} y `;
    }

    if (convertedTime.months && convertedTime.months !== 0) {
      convertedTimeString += `${convertedTime.months} m `;
    }

    if (convertedTime.days && convertedTime.days !== 0) {
      convertedTimeString += `${convertedTime.days} d `;
    }

    if (convertedTime.hours && convertedTime.hours !== 0) {
      convertedTimeString += `${convertedTime.hours} hr `;
    }

    if (convertedTime.minutes && convertedTime.minutes !== 0) {
      convertedTimeString += `${convertedTime.minutes} min `;
    }

    if (convertedTime.seconds && convertedTime.seconds !== 0) {
      convertedTimeString += `${Math.floor(convertedTime.seconds)} sec `;
    }

    return convertedTimeString;
  };

  const convertSeconds = (seconds) => {
    if (seconds <= 0) {
      return {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const secondsInMinute = 60;
    const secondsInHour = secondsInMinute * 60;
    const secondsInDay = secondsInHour * 24;
    const secondsInMonth = secondsInDay * 30;
    const secondsInYear = secondsInMonth * 12;

    if (seconds >= secondsInYear) {
      const years = Math.floor(seconds / secondsInYear);
      return {
        ...convertSeconds(seconds % secondsInYear),
        years,
      };
    } else if (seconds >= secondsInMonth) {
      const months = Math.floor(seconds / secondsInMonth);
      return {
        ...convertSeconds(seconds % secondsInMonth),
        months,
      };
    } else if (seconds >= secondsInDay) {
      const days = Math.floor(seconds / secondsInDay);
      return {
        ...convertSeconds(seconds % secondsInDay),
        days,
      };
    } else if (seconds >= secondsInHour) {
      const hours = Math.floor(seconds / secondsInHour);
      return {
        ...convertSeconds(seconds % secondsInHour),
        hours,
      };
    } else if (seconds >= secondsInMinute) {
      const minutes = Math.floor(seconds / secondsInMinute);
      return {
        ...convertSeconds(seconds % secondsInMinute),
        minutes,
      };
    } else {
      return {
        seconds,
      };
    }
  };

  const orderStatusColor = (orderStatus, statusCode) => {
    switch (orderStatus.toLowerCase()) {
      case "order delivered":
        return (
          <p className="capitalize p-color-green orderStatus_p">
            {orderStatus} {statusCode && <>({statusCode})</>}
          </p>
        );
      case "order removed":
        return (
          <p className="capitalize p-color-red orderStatus_p">
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      case "order not confirmed":
        return (
          <p className="capitalize p-color-yellow orderStatus_p">
            {orderStatus}
            {statusCode && <>({statusCode})</>}
          </p>
        );
      default:
        return (
          <p className="capitalize p-color-blue orderStatus_p">
            {orderStatus} {statusCode != null && <>({statusCode})</>}
          </p>
        );
    }
  };

  const isSelectedFleet = (incomingFleet) => {
    return selectedFleetDetails.find(
      (fleet) => fleet.fleetName === incomingFleet.fleetName
    )
      ? true
      : false;
  };

  const isSelectedRobot = (incomingRobot) => {
    return selectedRobotDetails.find(
      (robot) => robot.robotId === incomingRobot.robotId
    )
      ? true
      : false;
  };

  const handleFleetSelect = (incomingFleet) => {
    if (!isSelectedFleet(incomingFleet)) {
      if (selectedRobotDetails.length > 0) {
        setSelectedRobotDetails([]);
      }
      const selectedFleetUpdated = [
        ...selectedFleetDetails,
        fleetList.find((fleet) => fleet.fleetName === incomingFleet.fleetName),
      ];
      setSelectedFleetDetails(selectedFleetUpdated);
    } else {
      handleFleetDeselect(incomingFleet);
    }
    // setIsFleetDropdownOpen(true);
  };

  const handleRobotSelect = (incomingRobot) => {
    if (!isSelectedRobot(incomingRobot)) {
      const selectedRobotUpdated = [
        ...selectedRobotDetails,
        []
          .concat(...fleetList.map((fleet) => fleet.robots))
          .find((robot) => robot.robotId === incomingRobot.robotId),
      ];
      setSelectedRobotDetails(selectedRobotUpdated);
    } else {
      handleRobotDeselect(incomingRobot);
    }
    // setIsFleetDropdownOpen(true);
  };

  const handleFleetDeselect = (incomingFleet) => {
    if (selectedRobotDetails.length > 0) {
      setSelectedRobotDetails([]);
    }
    const selectedFleetDetailsUpdated = selectedFleetDetails.filter(
      (fleet) => fleet.fleetName !== incomingFleet.fleetName
    );
    setSelectedFleetDetails(selectedFleetDetailsUpdated);
    setIsFleetDropdownOpen(true);
  };

  const handleRobotDeselect = (incomingRobot) => {
    const selectedRobotDetailsUpdated = selectedRobotDetails.filter(
      (robot) => robot.robotId !== incomingRobot.robotId
    );
    setSelectedRobotDetails(selectedRobotDetailsUpdated);
    setIsRobotDropdownOpen(true);
  };

  const locationPositionLabelPriority = (position) => {
    if (position && position.locationName) {
      return ``;
    }

    if (position && position.latitude) {
      return `(Lat,Lng)`;
    }

    if (position && position.positionX) {
      return "X,Y";
    }
    return "";
  };

  const locationPositionValuePriority = (position) => {
    if (position && position.locationName) {
      return position.locationName;
    }

    if (position && position.latitude) {
      return `${position.latitude}, ${position.longitude}`;
    }

    if (position && position.positionX) {
      return `${position.positionX}, ${position.positionY}`;
    }
    return "-"
  };

  const fleetBasedRobotList = () => {
    if (selectedFleetDetails.length > 0) {
      return [].concat(...selectedFleetDetails.map((fleet) => fleet.robots));
      // selectedFleetDetails.find(
      //   (fleet) => fleet.fleetName === incomingFleet.fleetName
      // )
    } else {
      return [].concat(...fleetList.map((fleet) => fleet.robots));
    }
  };

  const handleExportData = async () => {
    setLoader(true);
    const allOrdersRecord = await fetchExcelRecords();
    
    const excelAllRows = [];
    allOrdersRecord.map((order) => {
      let excelRow = {};
      let pickUpData = {};
      let dropoffData = {};
      let currentStoreTimezone = order.timeZone ?? "America/New_York";  
      if (order?.pickup) {
        pickUpData[`Pickup ${locationPositionLabelPriority(order.pickup)}`] =
          locationPositionValuePriority(order.pickup);
        // excelRow["Pickup"] = locationPositionValuePriority(order.pickup)
      }

      if (order?.delivery) {
        dropoffData[
          `Delivery ${locationPositionLabelPriority(order.delivery)}`
        ] = locationPositionValuePriority(order.delivery);
        // excelRow["Delivery"] = locationPositionValuePriority(order.delivery)
      }
        pickUpData = {
          ...pickUpData,
          "Reached Pickup Date": order.reached_pickup
            ? new Date(order?.reached_pickup).toLocaleDateString("en-US", {
                timeZone: currentStoreTimezone,
              })
            : "-",
          "Reached Pickup Time": order.reached_pickup
            ? new Date(order.reached_pickup).toLocaleTimeString("en-US", {
                timeZone: currentStoreTimezone,
              })
            : "-",
          "Booking -> Reached Pickup": order?.pickupTransitTime ?? 0,
          "Reached Pickup -> Picked up": order?.pickupWaitTime ?? 0,
        };
      
      const orderInfo = {
        "Order Type": order.orderType,
        "Order ID": order.orderId,
        "Mobile Number": order.customerMobileNumber,
        "Order Status": order.orderStatus,
        "Creation Date": order.createdAt
          ? new Date(order.createdAt).toLocaleDateString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Creation Time": order.createdAt
          ? new Date(order.createdAt).toLocaleTimeString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Dispatch Date": order?.order_dispatched
          ? new Date(order?.order_dispatched).toLocaleDateString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Dispatch Time": order?.order_dispatched
          ? new Date(order?.order_dispatched).toLocaleTimeString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
      };

      dropoffData = {
        ...dropoffData,
        "Reached Dropoff Date": order?.reached_dropoff
          ? new Date(order.reached_dropoff).toLocaleDateString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Reached Dropoff Time": order?.reached_dropoff
          ? new Date(order.reached_dropoff).toLocaleTimeString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Order Delivered Time": order?.order_delivered
          ? new Date(order.order_delivered).toLocaleTimeString("en-US", {
              timeZone: currentStoreTimezone,
            })
          : "-",
        "Picked up -> Reached Dropoff": order?.dropoffTransitTime ?? 0,
        "Reached Dropoff -> Delivered": order?.dropoffWaitTime ?? 0,
        "Picked up -> Delivered":
        (order?.dropoffTransitTime ?? 0) + (order?.dropoffWaitTime ?? 0),
        "Booked -> Delivered": order?.order_delivered ? (new Date(order.order_delivered) -
            new Date(order.createdAt)) /
          1000.0 : "-" ,
        comments: "",
      };
      excelRow = { ...orderInfo, ...pickUpData, ...dropoffData };
      excelAllRows.push(excelRow);
      return null;
    });
    let exportedExcelSheetName = `${fleetData?.fleetName} - ${
      startDate
        ? new Date(startDate)
            .toLocaleString("en-US")
            .split(",")[0]
        : new Date()
            .toLocaleString("en-US")
            .split(",")[0]
    } - ${
      endDate
        ? new Date(endDate)
            .toLocaleString("en-US")
            .split(",")[0]
        : new Date()
            .toLocaleString("en-US")
            .split(",")[0]
    }`;
    const filteredData = filterEmptyColumns(excelAllRows);

    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    const columnWidths = excelAllRows.reduce((acc, row) => {
      Object.keys(row).map((key, colIndex) => {
        const cellContent = row[key];
        const cellLength = cellContent ? cellContent.toString().length : 0;
        acc[colIndex] = Math.max(acc[colIndex] || 0, cellLength);
        return null;
      });
      return acc;
    }, []);

    // Set column widths in the !cols property of the worksheet
    worksheet["!cols"] = columnWidths.map((width) => ({ width: width + 2 }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1");
    XLSX.writeFile(workbook, exportedExcelSheetName + ".xlsx");
    setLoader(false);
  };
  const updateState = useCallback(async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}order/list`,
        {
          // fleetId: selectedFleetDetails,
          fleetId: [].concat(...selectedFleetDetails.map((fleet) => fleet._id)),
          robotId: [].concat(
            ...selectedRobotDetails.map((robot) => robot.robotId)
          ),
          // fleetId: [props?.fleetData?._id],
          startDate: new Date(startDate ?? new Date() )?.toLocaleDateString("en-US"),
          endDate: new Date(endDate ?? new Date() )?.toLocaleDateString("en-US"),
          pageNo: page,
          size: size,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setChartOrderData(res.data?.data?.order);
        setTotalCount(res?.data?.data?.count);
        setCurrentSize(res?.data?.data?.pageOrdersCount);
        if (res?.data?.data?.count > size) {
          setPaginationLength(true);
        }
        if (res?.data?.data?.count % 5 !== 0) {
          setShowCount(Math.floor(res?.data?.data?.count / 5) + 1);
        } else {
          setShowCount(res?.data?.data?.count / 5);
          {/* setShowCount(Math.floor(res?.data?.data?.order?.length / 5); */}
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
        // toast.error("Something went wrong!");
      });
  }, [
    size,
    startDate,
    endDate,
    currentPage,
    selectedFleetDetails,
    selectedRobotDetails,
  ]);

  const fetchExcelRecords = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/list`,
        {
          // fleetId: selectedFleetDetails,
          fleetId: [].concat(...selectedFleetDetails.map((fleet) => fleet._id)),
          robotId: [].concat(
            ...selectedRobotDetails.map((robot) => robot.robotId)
          ),
          // fleetId: [props?.fleetData?._id],
          startDate: new Date(startDate ?? new Date()).toLocaleDateString("en-US"),
          endDate: new Date(endDate ?? new Date()).toLocaleDateString("en-US"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return res.data?.data?.order;
    } catch (err) {
      console.error(err);
      setLoader(false);
      toast.error(err.message);
    }
  };

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  const updatedAnalyticState = useCallback(async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}orders-analytics`,
        {
          fleetId: [].concat(...selectedFleetDetails.map((fleet) => fleet._id)),
          robotId: [].concat(
            ...selectedRobotDetails.map((robot) => robot.robotId)
          ),
          startDate: new Date(startDate ?? new Date()).toLocaleDateString("en-US"),
          endDate: new Date(endDate ?? new Date()).toLocaleDateString("en-US"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAnalyticsData(res.data.result);
      });
  }, [startDate, endDate, selectedFleetDetails, selectedRobotDetails]);

  const useIntervalAsyncForAnalytics = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);
  useIntervalAsyncForAnalytics(updatedAnalyticState, 10 * 60 * 1000);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div
            className={sidebarcollapse ? "col-md-2 collapseWidth" : "col-md-2"}
          >
            <DashboardSideBar
              dashboardopen={props.dashboardopen}
              active="analytics"
            />
            <DashboardBottomBar active="analytics" />
          </div>
          {loader && (
            <div className="fixed inset-0 flex items-center justify-center w-screen h-screen z-20 bg-[rgba(0,0,0,0.8)]">
              <div className="p-3 rounded-lg text-center">
                <Loader />
                <p className="text-white">Downloading...</p>
              </div>
            </div>
          )}

          <div
            className={`${
              sidebarcollapse ? "col-md-10 collapseWidth" : "col-md-10"
            }`}
          >
            <div
              className={
                isopen.userlogindata ? "" : "flex flex-col gap-4 h-full"
              }
            >
              <div className="justify-between Dashboard_page_robot_card_heading_wrapper">
                <h4 className="Dashboard_page_Robot_Card_heading">Analytics</h4>

                {/* <button
                  className="px-2.5 py-0.5 rounded-md bg-ottonomyBlue"
                  onClick={() => setAddLocationModalOpen(true)}
                >
                  <p className="text-white text-md">Add Location</p>
                </button> */}
              </div>

              <div
                className={`${
                  isFleetDropdownOpen || isRobotDropdownOpen
                    ? "block"
                    : "hidden"
                } fixed z-[2] w-screen h-screen`}
                onClick={() => {
                  isFleetDropdownOpen && setIsFleetDropdownOpen(false);
                  isRobotDropdownOpen && setIsRobotDropdownOpen(false);
                }}
              ></div>
              <div className="grid grid-cols-3 gap-3">
                {/* <div className="grid col-span-3 min-h-[50px] text-white bg-[#282F42] py-2 px-[30px] rounded-lg ">
          <span className="flex items-center text-lg font-semibold font-poppins">
            Data Analytics
          </span>
        </div> */}
                <div className="grid grid-cols-3 col-span-3 gap-3">
                  <div className="flex flex-col gap-1">
                    <span className="text-sm font-semibold text-gray-400">
                      Select Date
                    </span>

                    <Calendar />
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-sm font-semibold text-gray-400">
                      Select Fleet
                    </span>

                    <Listbox
                      as="div"
                      className="space-y-1"
                      value={selectedFleetDetails}
                      onChange={(value) => handleFleetSelect(value)}
                      open={isFleetDropdownOpen}
                    >
                      <div className="relative">
                        <span className="inline-block w-full rounded-md shadow-sm">
                          <Listbox.Button
                            className="text-white w-full text-left text-sm bg-[#282F42] py-2.5 px-3 relative cursor-pointer transition duration-150 ease-in-out rounded-md focus:outline-none sm:text-sm sm:leading-5"
                            onClick={() =>
                              setIsFleetDropdownOpen(!isFleetDropdownOpen)
                            }
                            open={isFleetDropdownOpen}
                          >
                            <span className="block truncate">
                              {selectedFleetDetails.length < 1
                                ? "All"
                                : `Selected ${
                                    selectedFleetDetails.length === 1
                                      ? "Fleet"
                                      : "Fleets"
                                  } (${selectedFleetDetails.length})`}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <svg
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                              >
                                <path
                                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                        </span>

                        <Transition
                          unmount={false}
                          show={isFleetDropdownOpen}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute z-10 w-full mt-1 rounded-md"
                        >
                          <Listbox.Options
                            static
                            className={`mt-0 p-0 z-10 overflow-auto text-base leading-6 rounded-lg bg-[#282F42] border-2 !border-[#00B7D4] shadow-xs focus:outline-none sm:text-sm sm:leading-5 w-full ${
                              fleetList.length > 4 && "overflow-y-scroll h-40"
                            } list-none`}
                          >
                            {fleetList.map((fleet, index) => {
                              const selected = isSelectedFleet(fleet);
                              return (
                                <Listbox.Option key={fleet} value={fleet}>
                                  {({ active }) => (
                                    <div
                                      className={`${
                                        active
                                          ? "text-white bg-ottonomyBlue"
                                          : "text-white"
                                      } cursor-pointer select-none relative py-2 pl-10 pr-4`}
                                    >
                                      <span
                                        className={`${
                                          selected
                                            ? "font-semibold"
                                            : "font-normal"
                                        } block truncate text-sm`}
                                      >
                                        {fleet.fleetName}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`${
                                            active
                                              ? "text-white"
                                              : "text-ottonomyBlue"
                                          } absolute inset-y-0 left-2 flex items-center`}
                                        >
                                          <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Listbox.Option>
                              );
                            })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>

                    {/* <Menu
              as="div"
              className="text-white text-sm bg-[#282F42] py-2.5 px-3 rounded-md relative"
            >
              <div>
                <Menu.Button className="flex justify-between w-full h-auto focus:outline-none ">
                  {Object.keys(selectedFleetDetails).length === 0
                    ? "All"
                    : selectedFleetDetails.fleetName}

                  <SlArrowDown className="ml-2 mt-1 font-semibold text-[#B7B7B7]" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute right-0 z-10 w-full ${
                    fleetList.length > 4 && "overflow-y-scroll h-40"
                  } mt-3 origin-top-right bg-[#282F42] border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                >
                  <div className="w-full">
                  <Menu.Item key={"robot__no_all"} value={"All"}>
                      {({ active }) => (
                        <button
                          onClick={() => setSelectedFleetDetails([])}
                          className={`${
                            active && "!bg-[#00b7d4]"
                          } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-white`}
                        >
                          <span className="text-left line-clamp-1">All</span>
                        </button>
                      )}
                    </Menu.Item>
                    {fleetList &&
                      fleetList?.map((fleet, index) => {
                        return (
                          <Menu.Item
                            key={"fleet__no" + index}
                            value={fleet._id}
                          >
                            {({ active }) => (
                              <button
                                onClick={() => setSelectedFleetDetails(fleet)}
                                className={`${
                                  active && "bg-[#00b7d4]"
                                } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-white`}
                              >
                                <span className="text-left line-clamp-1">
                                  {fleet.fleetName}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-sm font-semibold text-gray-400">
                      Select Robot
                    </span>

                    <Listbox
                      as="div"
                      className="space-y-1"
                      value={selectedRobotDetails}
                      onChange={(value) => handleRobotSelect(value)}
                      open={isRobotDropdownOpen}
                    >
                      <div className="relative">
                        <span className="inline-block w-full rounded-md shadow-sm">
                          <Listbox.Button
                            className="text-white w-full text-left text-sm bg-[#282F42] py-2.5 px-3 relative cursor-pointer transition duration-150 ease-in-out rounded-md focus:outline-none sm:text-sm sm:leading-5"
                            onClick={() =>
                              setIsRobotDropdownOpen(!isRobotDropdownOpen)
                            }
                            open={isRobotDropdownOpen}
                          >
                            <span className="block truncate">
                              {selectedRobotDetails.length < 1
                                ? "All"
                                : `Selected ${
                                    selectedRobotDetails.length === 1
                                      ? "Robot"
                                      : "Robots"
                                  } (${selectedRobotDetails.length})`}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <svg
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                              >
                                <path
                                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                        </span>

                        <Transition
                          unmount={false}
                          show={isRobotDropdownOpen}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute z-10 w-full mt-1 rounded-md"
                        >
                          <Listbox.Options
                            static
                            className={`mt-0 p-0 z-10 overflow-auto text-base leading-6 rounded-lg bg-[#282F42] border-2 !border-[#00B7D4] shadow-xs focus:outline-none sm:text-sm sm:leading-5 w-full ${
                              fleetBasedRobotList().length > 4 &&
                              "overflow-y-scroll h-40"
                            } list-none`}
                          >
                            {fleetBasedRobotList().map((robot, index) => {
                              const selected = isSelectedRobot(robot);
                              return (
                                <Listbox.Option key={robot} value={robot}>
                                  {({ active }) => (
                                    <div
                                      className={`${
                                        active
                                          ? "text-white bg-ottonomyBlue"
                                          : "text-white"
                                      } cursor-pointer select-none relative py-2 pl-10 pr-4`}
                                    >
                                      <span
                                        className={`${
                                          selected
                                            ? "font-semibold"
                                            : "font-normal"
                                        } block truncate text-sm`}
                                      >
                                        {robot.robotId}
                                      </span>
                                      {selected && (
                                        <span
                                          className={`${
                                            active
                                              ? "text-white"
                                              : "text-ottonomyBlue"
                                          } absolute inset-y-0 left-2 flex items-center`}
                                        >
                                          <svg
                                            className="w-5 h-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Listbox.Option>
                              );
                            })}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>

                    {/* <Menu
              as="div"
              className="text-white text-sm bg-[#282F42] py-2.5 px-3 rounded-md relative"
            >
              <div>
                <Menu.Button className="flex justify-between w-full h-auto focus:outline-none ">
                  {Object.keys(selectedRobotDetails).length === 0
                    ? "All"
                    : selectedRobotDetails.name}

                  <SlArrowDown className="ml-2 mt-1 font-semibold text-[#B7B7B7]" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`absolute right-0 z-10 w-full ${
                    fleetList && fleetList.length > 0 && [].concat(...fleetList.map(fleet => fleet.robots)).length > 4 && "overflow-y-scroll h-40"
                  } mt-3 origin-top-right bg-[#282F42] border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                >
                  <div className="w-full">
                    <Menu.Item key={"robot__no_all"} value={"All"}>
                      {({ active }) => (
                        <button
                          onClick={() => setSelectedRobotDetails([])}
                          className={`${
                            active && "!bg-[#00b7d4]"
                          } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-white`}
                        >
                          <span className="text-left line-clamp-1">All</span>
                        </button>
                      )}
                    </Menu.Item>
                    {fleetList && fleetList.length > 0 &&
                      // fleetList[0]?.robots &&
                      // fleetList[0]?.robots?
                      [].concat(...fleetList.map(fleet => fleet.robots))?.map((robot, index) => {
                        return (
                          <Menu.Item
                            key={"robot__no_" + index}
                            value={robot._id}
                          >
                            {({ active }) => (
                              <button
                                onClick={() => setSelectedRobotDetails(robot)}
                                className={`${
                                  active && "!bg-[#00b7d4]"
                                } group flex w-full px-2 items-center justify-start rounded-md py-2 text-sm text-white`}
                              >
                                <span className="text-left line-clamp-1">
                                  {robot.robotId}
                                </span>
                              </button>
                            )}
                          </Menu.Item>
                        );
                      })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
                  </div>
                </div>
                <div className="grid grid-cols-3 col-span-3 gap-3 h-max">
                  <div className="flex flex-col items-start w-full col-span-1 gap-2">
                    <span className="text-lg font-semibold text-[#00b7d4]">
                      Orders Data
                    </span>
                    <div className="flex flex-col w-full gap-3 flex-grow-1">
                      <div className="flex flex-col bg-[#282F42] rounded-md flex-grow-1 p-4 gap-2">
                        <div className="flex flex-col">
                          <span className="text-sm font-semibold text-gray-400">
                            Total No. Of Orders
                          </span>
                          <span className="text-3xl font-semibold text-[#00b7d4]">
                            {analyticsData.hasOwnProperty("totalCount")
                              ? analyticsData?.totalCount
                              : 0}
                          </span>
                        </div>
                        <div className="flex items-center justify-between p-3 bg-[#1C1E2A] rounded-md">
                          <div className="flex flex-col gap-0">
                            <span className="text-[#6DBEBF] text-xl leading-5">
                              {analyticsData.hasOwnProperty("completedCount")
                                ? analyticsData?.completedCount
                                : 0}
                            </span>
                            <span className="text-[#6DBEBF] text-sm font-light">
                              Order Delivered
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-[#ED6D85] text-xl leading-5">
                              {analyticsData.hasOwnProperty("returnedCount")
                                ? analyticsData?.returnedCount
                                : 0}
                            </span>
                            <span className="text-[#ED6D85] text-sm font-light">
                              Order Cancelled
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-grow-1 bg-[#282F42] rounded-md p-4">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex flex-col ">
                            <span className="text-sm font-semibold text-gray-400">
                              Total Distance
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "totalOrderDistance"
                              )
                                ? (
                                    analyticsData?.orderMovement
                                      ?.totalOrderDistance * 0.00062137
                                  ).toFixed(2)
                                : 0}{" "}
                              miles
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-semibold text-gray-400">
                              Average Distance
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "avgOrderDistance"
                              )
                                ? (
                                    analyticsData?.orderMovement
                                      ?.avgOrderDistance * 0.00062137
                                  ).toFixed(2)
                                : 0}{" "}
                              miles
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col flex-grow-1 bg-[#282F42] rounded-md p-4">
                        <div className="flex items-center justify-between p-2">
                          <div className="flex flex-col ">
                            <span className="text-sm font-semibold text-gray-400">
                              Total Time
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "totalOrderTime"
                              )
                                ? customFormatTime(
                                    analyticsData?.orderMovement?.totalOrderTime
                                  ) !== ""
                                  ? customFormatTime(
                                      analyticsData?.orderMovement
                                        ?.totalOrderTime
                                    )
                                  : "0 sec"
                                : "0 sec"}{" "}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm font-semibold text-gray-400">
                              Average Time
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "avgOrderTime"
                              )
                                ? customFormatTime(
                                    analyticsData?.orderMovement?.avgOrderTime
                                  ) !== ""
                                  ? customFormatTime(
                                      analyticsData?.orderMovement?.avgOrderTime
                                    )
                                  : "0 sec"
                                : "0 sec"}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2 rounded-md flex bg-[#282F42] p-4 relative">
                    <div className="flex flex-col gap-1 absolute right-0 top-2 z-[5]">
                      <Menu
                        as="div"
                        className="text-white text-xs bg-[#282F42] py-2.5 px-3 rounded-md relative"
                      >
                        <div>
                          <Menu.Button className="flex justify-between w-full h-auto pb-1 border-b focus:outline-none border-b-ottonomyBlue">
                            {stackedBarDataKey.label}
                            <SlArrowDown className="ml-2 mt-1 font-semibold text-[#B7B7B7]" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute right-0 z-10 w-full ${
                              chartTimePeriods.length > 4 &&
                              "overflow-y-scroll h-40"
                            } mt-1 origin-top-right bg-[#282F42] border-2 !border-[#00B7D4] divide-y divide-gray-100 rounded-lg focus:outline-none`}
                          >
                            <div className="w-full">
                              {chartTimePeriods.map((timePeriod, index) => {
                                return (
                                  <Menu.Item
                                    key={"distribution__no_" + index}
                                    value={timePeriod}
                                  >
                                    {({ active }) => (
                                      <button
                                        onClick={() =>
                                          setStackedBarDataKey(timePeriod)
                                        }
                                        className={`${
                                          active && "!bg-[#00b7d4]"
                                        } group flex w-full px-2 items-center justify-start rounded-md py-2 text-xs text-white`}
                                      >
                                        <span className="text-left line-clamp-1">
                                          {timePeriod.label}
                                        </span>
                                      </button>
                                    )}
                                  </Menu.Item>
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>

                    <div className="w-full h-full">
                      {analyticsData &&
                        analyticsData.hasOwnProperty(stackedBarDataKey.value) &&
                        analyticsData[stackedBarDataKey.value].length > 0 && (
                          <StackedBarChart
                            analyticsData={analyticsData}
                            dataKey={stackedBarDataKey.value}
                          />
                        )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 col-span-3 gap-3 h-max">
                  <div className="flex flex-col items-start w-full h-auto col-span-1 gap-2">
                    <span className="text-lg font-semibold text-[#00b7d4]">
                      Average Time
                    </span>
                    <div className="flex flex-col flex-grow-1 w-full rounded-md bg-[#282F42] p-3 pt-4">
                      <div className="flex flex-col gap-3">
                        {analyticsData?.orderMovement?.avgPickUpTime ||
                        analyticsData?.orderMovement?.avgPickUpWaitTime ? (
                          <div className="flex items-center justify-between p-2">
                            {analyticsData?.orderMovement?.avgPickUpTime ? (
                              <div className="flex flex-col ">
                                <span className="text-sm font-semibold text-gray-400">
                                  Pickup Time
                                </span>
                                <span className="text-3xl font-semibold text-[#00b7d4]">
                                  {analyticsData.hasOwnProperty(
                                    "orderMovement"
                                  ) &&
                                  analyticsData.orderMovement.hasOwnProperty(
                                    "avgPickUpTime"
                                  )
                                    ? customFormatTime(
                                        analyticsData?.orderMovement
                                          ?.avgPickUpTime
                                      ) !== ""
                                      ? customFormatTime(
                                          analyticsData?.orderMovement
                                            ?.avgPickUpTime
                                        )
                                      : "0 sec"
                                    : "0 sec"}{" "}
                                </span>
                              </div>
                            ) : null}
                            {analyticsData?.orderMovement?.avgPickUpWaitTime ? (
                              <div className="flex flex-col ">
                                <span className="text-sm font-semibold text-gray-400">
                                  Pickup Wait Time
                                </span>
                                <span className="text-3xl font-semibold text-[#00b7d4]">
                                  {analyticsData.hasOwnProperty(
                                    "orderMovement"
                                  ) &&
                                  analyticsData.orderMovement.hasOwnProperty(
                                    "avgPickUpWaitTime"
                                  )
                                    ? customFormatTime(
                                        analyticsData?.orderMovement
                                          ?.avgPickUpWaitTime
                                      ) !== ""
                                      ? customFormatTime(
                                          analyticsData?.orderMovement
                                            ?.avgPickUpWaitTime
                                        )
                                      : "0 sec"
                                    : "0 sec"}{" "}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        <div className="flex items-center justify-between p-2">
                          <div className="flex flex-col ">
                            <span className="text-sm font-semibold text-gray-400">
                              Delivery Time
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "avgDropOffTime"
                              )
                                ? customFormatTime(
                                    analyticsData?.orderMovement?.avgDropOffTime
                                  ) !== ""
                                  ? customFormatTime(
                                      analyticsData?.orderMovement
                                        ?.avgDropOffTime
                                    )
                                  : "0 sec"
                                : "0 sec"}{" "}
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-sm font-semibold text-gray-400">
                              Delivery Wait Time
                            </span>
                            <span className="text-3xl font-semibold text-[#00b7d4]">
                              {analyticsData.hasOwnProperty("orderMovement") &&
                              analyticsData.orderMovement.hasOwnProperty(
                                "avgOrderDropOffWaitTime"
                              )
                                ? customFormatTime(
                                    analyticsData?.orderMovement
                                      ?.avgOrderDropOffWaitTime
                                  ) !== ""
                                  ? customFormatTime(
                                      analyticsData?.orderMovement
                                        ?.avgOrderDropOffWaitTime
                                    )
                                  : "0 sec"
                                : "0 sec"}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start h-auto col-span-1 gap-2">
                    <span className="text-lg font-semibold text-[#00b7d4]">
                      Robot Distribution
                    </span>
                    <div className="flex w-full flex-grow-1 gap-4 rounded-md bg-[#282F42] p-3 pt-4">
                      <RobotPolarChart analyticsData={analyticsData} />
                    </div>
                  </div>
                  <div className="flex flex-col items-start h-auto col-span-1 gap-2">
                    <span className="text-lg font-semibold text-[#00b7d4]">
                      Cabin Distribution
                    </span>
                    <div className="flex w-full gap-4 flex-grow-1 rounded-md bg-[#282F42] p-3 pt-4">
                      <CabinPolarChart analyticsData={analyticsData} />
                    </div>
                  </div>
                </div>

                {/* <div className="grid grid-cols-3 col-span-3 gap-3 h-max">
          <div className="flex flex-col items-start h-auto col-span-1 gap-2">
            <span className="text-lg font-semibold text-[#00b7d4]">
              Cabin Distribution
            </span>
            <div className="flex w-full gap-4 flex-grow-1 rounded-md bg-[#282F42] p-3 pt-4">
              <CabinPolarChart analyticsData={analyticsData} />
            </div>
          </div>
        </div> */}

                <div className="grid grid-cols-3 col-span-3 gap-2 h-max">
                  <div className="flex flex-col items-start h-auto col-span-1 gap-2">
                    <span className="text-lg font-semibold text-[#00b7d4]">
                      Order Heatmap
                    </span>
                  </div>
                  <div className="flex w-full col-span-3 min-h-[500px] bg-[#282F42] flex-grow-1 rounded-md">
                    {/* {!analyticsData.hasOwnProperty("locationCount") ||
            analyticsData.locationCount.length === 0 ? (
              <span className="flex items-center justify-center w-full text-lg font-semibold text-gray-400">
                No data to show
              </span>
            ) : ( */}
                    <OrdersHeatMap
                      analyticsData={analyticsData}
                      selectedFleetDetails={selectedFleetDetails}
                      selectedRobotDetails={selectedRobotDetails}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start w-full mt-3">
                <div className="flex items-center justify-between w-full">
                  <span className="text-lg font-semibold text-[#00b7d4] ">
                    Order List
                  </span>
                  {chartOrderData?.length > 0 && (
                    <div className="flex items-center justify-center px-3 py-1 rounded-md bg-ottonomyBlue">
                      <button onClick={handleExportData} className="text-white">
                        {" "}
                        <img
                          className="Export_excel_image "
                          src="/assets/images/Export_excel.svg"
                          alt=""
                        />
                        Export Excel
                      </button>
                    </div>
                  )}
                </div>
                <div className="mt-2 w-full bg-[#282F42] flex flex-col gap-2 p-4 overflow-auto rounded-md">
                  <table>
                    <thead className="text-[rgb(255,255,255,0.7)] text-sm border-b !border-b-[#00B7D4]">
                      <tr>
                        <th className="p-3">Robot ID</th>
                        <th className="p-3">Order ID</th>
                        <th className="p-3">Status</th>
                        <th className="p-3">Compartment</th>
                        {/* <th className="p-3">
                          Delivery/Pickup{" "}
                          {chartOrderData !== undefined &&
                            chartOrderData.length > 0 &&
                            locationPositionLabelPriority(
                              chartOrderData[0].delivery
                            )}
                        </th> */}
                        {chartOrderData !== undefined &&
                          chartOrderData.length > 0 &&
                          fleetData?.booking?.pickup &&
                          fleetData?.booking?.pickup.length !== 0 && (
                            <th className="p-3">
                              Pickup{" "}
                              {locationPositionLabelPriority(
                                chartOrderData[0]?.pickup
                              )}
                            </th>
                          )}
                        {chartOrderData !== undefined &&
                          chartOrderData.length > 0 &&
                          fleetData?.booking?.delivery &&
                          fleetData?.booking?.delivery.length !== 0 && (
                            <th className="p-3 ">
                              Delivery{" "}
                              {locationPositionLabelPriority(
                                chartOrderData[0]?.delivery
                              )}
                            </th>
                          )}
                        {/* <th className="p-3">Order Info</th> */}
                        <th className="p-3">Time Stamp</th>
                        <th className="p-3"></th>
                      </tr>
                    </thead>
                    <tbody className="text-white">
                      {(!chartOrderData || chartOrderData.length === 0) && (
                        <tr className={`rounded-t-md`}>
                          <td
                            align="center"
                            colSpan={8}
                            className="p-2 font-semibold text-gray-400 rounded-b-md !text-base"
                          >
                            No Order to show
                          </td>
                        </tr>
                      )}
                      {chartOrderData !== undefined &&
                        chartOrderData.length > 0 &&
                        chartOrderData.map((order, index) => {
                          return (
                            <CustomAccordion
                              key={"order__list__item__" + index}
                              order={order}
                              orderStatusColor={orderStatusColor}
                              orderIndex={index}
                              chartOrderData={chartOrderData}
                              customFormatTime={customFormatTime}
                              fleetData={fleetData}
                              locationPositionValuePriority={
                                locationPositionValuePriority
                              }
                            />
                          );
                        })}
                    </tbody>
                  </table>
                  {chartOrderData && chartOrderData.length > 0 && (
                    <div className="flex justify-between px-2.5 mt-2">
                      <span className="font-bold text-ottonomyBlue">
                        {" "}
                        {currentSize < size
                          ? size - size
                          : (page - 1)  * size + 1}{" "}
                        - {page * size >= totalCount ? totalCount : page * size} of {totalCount}{" "}
                      </span> 

                      <div>
                        {paginationlength && (
                          <ThemeProvider theme={paginationTheme}>
                            <Pagination
                              className="PaginationWrapper"
                              count={showCount}
                              page={page}
                              onChange={handlePagination}
                              sx={{ width: "100%" }}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="Reports_div_wrapper d-flex-center">
        <div className="py-[8px] px-[30px]">
          {" "}
          <p className="FleetView_page_haeding_tag"> Reports</p>{" "}
        </div>
        <div>
          <Calendar />
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-md-4">
          <div className="Reports_page_graph_div">
            {chartOrderData !== undefined && chartOrderData.length > 0 ? (
              <TotalOrdersChart orderlist={chartOrderData} />
            ) : (
              <div className="text-white py-[8px] px-[30px]">
                No Data to show
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default OrdersReports;
